<template>
  <!-- C++ and Python -->
  <div class="editor" style="border: 1px solid #f2f2f6;">
    <codemirror
      ref="cmEditor"
      :value="code"
      :options="cmOptions"
      @ready="onCmReady"
      @focus="onCmFocus"
      @input="onCmCodeChange"
    />
  </div>
</template>

<script>

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/python/python.js'
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/show-hint.js'

export default {
  name: 'Editor',
  props: {
    answer: {
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  components: {
    codemirror
  },
  data() {
    return {
      code: '',
      cmOptions: {
        tabSize: 4,
        mode: { name: 'text/x-python' },
        lineNumbers: true,
        line: true,
        styleActiveLine: true
      }
    }
  },
  computed: {
    codemirror() {
      return this.$refs.cmEditor.codemirror
    }
  },
  created() {
    if (this.type === 1) {
      this.cmOptions.mode = { name: 'text/x-c++src' }
    }
  },
  mounted() {
    this.code = this.answer ? this.answer : ''
  },
  methods: {
    onCmReady(cm) {
      console.log('the editor is readied!', cm)
    },
    onCmFocus(cm) {
      console.log('the editor is focused!', cm)
    },
    onCmCodeChange(newCode) {
      this.code = newCode
      this.$emit('update-answer', this.code)
    }
  },
}
</script>